 <template>
  <div class="nq-form padding-10">
    <div class="form-list">
      <div
        class="form-item"
        v-for="item in formList"
        :key="item.label"
        :class="item.inline ? 'item-inline' : ''"
        :style="'width:' + item.width + 'px'"
      >
        <div class="form-item-label">
          <span style="color: red" v-if="!item.norequire">*</span>
          {{ item.label }}
        </div>
        <component
          :disabled="item.disabled"
          :placeholder="item.placeholder || item.label"
          :type="item.inputType || 'text'"
          :is="item.type"
          class="form-item-ele"
          :value="values[item.key]"
          @blur="(e) => formChange(e, item.key)"
          :maxlength="item.maxlength || 50"
        >
          <template v-if="item.options">
            <option value="">请选择</option>
            <option
              v-for="selectItem in item.options"
              :key="selectItem.value"
              :label="selectItem.label"
              :value="selectItem.value"
            >
            {{selectItem.label}}
            </option>
          </template>
        </component>
        <div class="error-text">{{ item.errorTips }}</div>
      </div>
    </div>
    <div class="form-end" v-if="submitText || cancelText">
      <template v-if="submitText">
        <div class="nq-button" @click="validateForm">
          {{ submitText }}
        </div>
      </template>
      <template v-if="cancelText">
        <div class="nq-button-back" @click="$emit('cancel')">
          {{ cancelText }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { trimSpaces } from '../utils/publicFun'
export default {
  name: 'nq-form',
  props: ['formList', 'values', 'submitText', 'cancelText'],
  data() {
    return {
      resultValue: {}
    }
  },
  watch: {
    values: {
      handler(val) {
        this.resultValue = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    formChange(e, key) {
      this.resultValue[key] = trimSpaces(e.target.value)
    },
    submitForm() {
      this.validateForm()
    },
    validateForm() {
      let errorNum = 0
      this.formList.forEach((item) => {
        item.errorTips = ''
        const value = trimSpaces(this.resultValue[item.key])
        item.value = trimSpaces(this.resultValue[item.key]) || ''
        // 必填校验
        if (!item.norequire) {
          if (value === '' || value === null || value === undefined) {
            item.errorTips = item.label + '不能为空'
            errorNum++
          }
        }
        // 自定义校验
        if (item.validate && item.validate.length && item.value !== '') {
          item.validate.forEach((valiItem, index) => {
            if (typeof valiItem === 'function') {
              const res = valiItem(this.formList, item, index)
              if (!res.validateRes) {
                errorNum++
                item.errorTips = res.message
              }
            }
          })
        }
      })
      this.$forceUpdate()
      if (errorNum === 0) {
        this.$emit('submit', this.resultValue)
      }
    }
  }
}
</script>
<style scoped>
.error-text {
  height: 20px;
  line-height: 20px;
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: left;
}
.form-item-label {
  font-size: 15px;
  margin-bottom: 3px;
}
.form-item-ele {
  height: 35px;
  width: calc(100% - 16px);
  padding-left: 8px;
}
.form-end {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}
.item-inline {
  display: inline-block;
}
</style>
